import { ErrorEvent, EventHint, Breadcrumb } from '@sentry/types'

export const shouldIgnoreError = (
  error: ErrorEvent,
  _hint: EventHint
): boolean => isIgnoredNetworkError(error) || isIgnoredJavascriptError(error)

export const NETWORK_EXCEPTION_TYPE = 'TypeError'
export const typeErrorFetchFailedValues = new Set([
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
])
export const DISPOSED_ERROR_STRING = `Hook "disposed" is disposed`
export const AUTOFILL_CALLBACK_HANDLER_ERROR_STRING =
  "Can't find variable: _AutofillCallbackHandler"
export const AUTOFILL_POPUP_CLOSE_ERROR_STRING =
  "Can't find variable: __AutoFillPopupClose__"
const ignoredJavascriptErrors = new Set([
  DISPOSED_ERROR_STRING,
  AUTOFILL_CALLBACK_HANDLER_ERROR_STRING,
  AUTOFILL_POPUP_CLOSE_ERROR_STRING,
])

const isIgnoredJavascriptError = (error: ErrorEvent): boolean => {
  const exceptionValue = error.exception?.values?.[0]?.value
  return ignoredJavascriptErrors.has(exceptionValue as string)
}

const isIgnoredNetworkError = (error: ErrorEvent): boolean => {
  const exception = error.exception?.values?.[0]

  if (
    exception?.type !== NETWORK_EXCEPTION_TYPE ||
    !typeErrorFetchFailedValues.has(exception.value as string)
  ) {
    return false
  }

  if (!error.breadcrumbs) {
    return false
  }

  const now = Date.now()
  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb = error.breadcrumbs[i]
    if (!breadcrumb) continue

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
      break
    }

    if (isIgnoredNetworkBreadcrumb(breadcrumb)) {
      return true
    }
  }

  return false
}

const IGNORED_REGEX = /pinterest\.com/

const isIgnoredNetworkBreadcrumb = (breadcrumb: Breadcrumb): boolean => {
  if (
    breadcrumb.level !== 'error' ||
    (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')
  ) {
    return false
  }

  const url = breadcrumb.data?.url as string | undefined
  return !!url && IGNORED_REGEX.test(url)
}
